.aboutMe {
  height: 100%;
  min-height: 90vh;
  background-color: #0077b5;
  text-align: center;
  padding: 10vh 10vw;
}

.aboutMe h1 {
  font-family: "Avenir Roman";
  font-size: 34px;
  letter-spacing: 1px;
}

.aboutMe p {
  font-size: 26px;
}

.profilePic {
  width: auto;
  height: 40vh;
  border-radius: 50%;
}