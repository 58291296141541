.navigation {
  position: sticky;
  top: 0;
  background-color: #0077b5;
  min-height: 10vh;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0vh 5vw;
}

.navLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.projectsSvg {
  height: 32px;
  width: 110px;
}

.miniProjectsSvg {
  height: 32px;
  width: 150px;
}

.skillsSvg {
  height: 32px;
  width: 80px;
}

.backSvg {
  height: 32px;
  width: 70px;
}

.svgWrapper {
  margin: 0vh 0.5vw;
  padding: 2vh 0vw;
  text-align: center;
  text-decoration: none;
  font-family: "Avenir Roman";
  letter-spacing: 0px;
  text-transform: uppercase;
}

.projectsShape {
  stroke-dasharray: 65 270;
  /*#length of underline #don't touch*/
  stroke-dashoffset: -165;
  /*position of underline, x axis => +goes right -goes left*/
  stroke-width: 6px;
  fill: transparent;
  stroke: black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s,
    stroke 1s;
}

.miniProjectsShape {
  stroke-dasharray: 95 270;
  /*#length of underline #don't touch | speed of animation*/
  stroke-dashoffset: -210;
  /*position of underline, x axis => +goes right -goes left*/
  stroke-width: 6px;
  fill: transparent;
  stroke: black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s,
    stroke 1s;
}

.skillsShape {
  stroke-dasharray: 40 270;
  /*#length of underline #don't touch*/
  stroke-dashoffset: -132;
  /*position of underline, x axis => +goes right -goes left*/
  stroke-width: 6px;
  fill: transparent;
  stroke: black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s,
    stroke 1s;
}

.backShape {
  stroke-dasharray: 35 270;
  /*#length of underline #don't touch*/
  stroke-dashoffset: -120;
  /*position of underline, x axis => +goes right -goes left*/
  stroke-width: 6px;
  fill: transparent;
  stroke: black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s,
    stroke 1s;
}

.svgWrapper:hover .projectsShape,
.svgWrapper:focus .projectsShape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 285;
  /*Change this value to make the lines meet at the same time*/
  stroke: white;
  text-decoration: none;
}

.svgWrapper:hover .miniProjectsShape,
.svgWrapper:focus .miniProjectsShape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 365;
  stroke: white;
  text-decoration: none;
}

/* .svgWrapper:hover .skillsShape,
.svgWrapper:focus .skillsShape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 225;
  stroke: white;
  text-decoration: none;
} */

.svgWrapper:hover .backShape,
.svgWrapper:focus .backShape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 205;
  stroke: white;
  text-decoration: none;
}

.svgWrapper .svgtext {
  transition: all 1s;
  font-size: 18px;
}

.svgWrapper:hover .svgtext,
.svgWrapper:focus .svgtext {
  fill: white;
}

.navRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navRight>a {
  margin: 0vh 1vw;
  transition: all 1s;
}

.navRight svg {
  height: 32px;
  width: auto;
  transition: all 1s;
}

.navRight svg:hover,
.navRight a:focus {
  fill: white;
}

@media screen and (max-width: 768px) {
  .navigation {
    padding: 0vh 0.5vw;
  }
}