.project {
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.miniProject {
  height: 90vh;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.projectImg,
.projectText {
  padding: 5vh 5vw;
}

.projectText>h2 {
  font-family: "Avenir Roman";
  letter-spacing: 2px;
  font-size: 32px;
}

.projectImg {
  flex: 60%;
  max-width: 60%;
  height: auto;
}

.projectText {
  flex: 40%;
  max-width: 40%;
  height: auto;
}

.projectImg>img {
  width: 100%;
  box-shadow: 10px 10px 30px black;
  /*opacity: 0;
	transition: opacity 250ms ease-in;*/
}

.projectText {
  overflow-wrap: break-word;
}

.projectText p {
  font-family: "Futura PT Light";
  font-size: 24px;
}

.projectLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}

.projectLink {
  margin: 2vh 1vw;
  padding: 1vh 1vw;
  text-decoration: none;
  border: solid #0077b5;
  color: #0077b5;
  transition: all 1s;
  font-family: "Avenir Roman";
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
}

.miniProject .projectLink {
  margin: 2vh 1vw;
  padding: 1vh 1vw;
  text-decoration: none;
  border: solid #005eb5;
  color: #005eb5;
  transition: all 1s;
}

.projectLink:hover,
.projectLink:focus {
  color: #f9f9f9;
  background-color: #0077b5;
}

.miniProject .projectLink:hover,
.miniProject .projectLink:focus {
  color: #f9f9f9;
  background-color: #005eb5;
}

@media screen and (max-width: 768px) {

  .projectImg,
  .projectText {
    flex: 100%;
    max-width: 100%;
  }
}