.projectPageBackground {
  background-color: #f9f9f9;
}

.projectPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.projectTitle {
  padding: 5vh 0vw;
  margin: 0px;
  text-align: center;
  font-family: "Avenir Roman";
  letter-spacing: 2px;
  font-size: 46px;
}

.text {
  flex: 60%;
  max-width: 60%;
  height: auto;
  padding: 0vh 2.5vw 0vh 5vw;
  overflow-wrap: break-word;
  font-family: "Futura PT Light";
  font-size: 24px;
}

.textLink {
  color: black;
}

.technologies {
  flex: 40%;
  max-width: 40%;
  height: auto;
  padding: 0vh 5vw 0vh 2.5vw;
  font-family: "Futura PT Light";
  font-size: 24px;
}

.technologies p {
  padding: 3vh 0vw 1vh 0vw;
  margin: 0;
}

.technologies h2 {
  text-align: center;
  margin: 0;
  padding: 7vh 0vw 5vh 0vw;
}

.logos {
  display: grid;
  gap: 5px;
  grid-template-columns: 25% 25% 25% 25%;
  justify-items: center;
  align-items: center;
}

.logos>a {
  margin: 1vh 1vw;
}

.logos svg {
  height: auto;
  width: 90px;
  transition: all 1s;
}

.logos img {
  width: auto;
  height: 80px;
  transition: all 1s;
}

.logos img:hover,
.logos img:focus,
.logos svg:hover,
.logos svg:focus {
  transform: scale(1.1);
}

.projectPageBackground .projectLinks {
  padding: 5vh 0;
}

.pwaImg {
  max-width: 90%;
  height: auto;
}

.pwaImg>img {
  width: 100%;
}

@media screen and (max-width: 1024px) {

  .text,
  .technologies {
    flex: 100%;
    max-width: 100%;
  }

  .logos {
    grid-template-columns: 33% 33% 33%;
  }
}