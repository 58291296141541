@font-face {
  font-family: 'Futura PT Light';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Light'), url('./assets/FuturaCyrillicLight.woff') format('woff');
}

@font-face {
  font-family: "Avenir Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Roman"), url("./assets/AvenirLTStd-Roman.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "Futura PT Light";
  letter-spacing: 0.5px;
  color: black;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}