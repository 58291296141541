.projects {
  background-color: #f9f9f9;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.projects>h1 {
  padding: 10vh 10vw 0vh 10vw;
  margin: 0px;
  font-family: "Avenir Roman";
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 38px;
}

.miniProjects {
  background-color: #e6eff3;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.miniProjects>h1 {
  padding: 10vh 10vw 0vh 10vw;
  margin: 0px;
  font-family: "Avenir Roman";
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 38px;
}